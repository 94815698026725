import React, { useState, useEffect, useRef } from "react";

interface ProgressBarProps {
  currentTime: number; // Current playback time in seconds
  duration: number;    // Total video duration in seconds
  // onProgressChange: (value: number) => void;
  onSeekStart?: () => void;
  onSeekEnd?: (value: number) => void;
}

const formatTime = (seconds: number) => {
  if (isNaN(seconds)) return "00:00";
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  if (h > 0) {
    return `${h}:${m.toString().padStart(2,"0")}:${s.toString().padStart(2,"0")}`;
  }
  return `${m.toString().padStart(2,"0")}:${s.toString().padStart(2,"0")}`;
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentTime,
  duration,
  // onProgressChange,
  onSeekStart,
  onSeekEnd,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipTime, setTooltipTime] = useState("00:00");
  const [tooltipPosition, setTooltipPosition] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isDragging && duration > 0) {
      const progress = (currentTime / duration) * 100;
      setSliderValue(progress);
    }
  }, [currentTime, duration, isDragging]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    setSliderValue(value);
    // Only call onProgressChange here if you want immediate feedback while dragging.
    // Otherwise, leave it out if you only want to update when user releases the handle.
  };

  const handleMouseDown = () => {
    setIsDragging(true);
    setShowTooltip(true);
    onSeekStart?.();
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    const finalValue = sliderValue;
    onSeekEnd?.(finalValue);
    // Keep tooltip visible only during drag. If you prefer to always show on hover, handle accordingly.
    setShowTooltip(false);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
    setShowTooltip(true);
    onSeekStart?.();
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    const finalValue = sliderValue;
    onSeekEnd?.(finalValue);
    setShowTooltip(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (inputRef.current && duration > 0) {
      const rect = inputRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left; // x position relative to the element
      const percentage = Math.min(Math.max(x / rect.width, 0), 1);
      const hoverTime = percentage * duration;

      setTooltipTime(formatTime(hoverTime));
      setTooltipPosition(x); 
      
      // If not dragging, show the tooltip only when hovering over the progress bar
      if (!isDragging) {
        setShowTooltip(true);
      }
    }
  };

  const handleMouseLeave = () => {
    if (!isDragging) {
      setShowTooltip(false);
    }
  };

  return (
    <div 
      style={{ position: "relative", width: "100%" }} 
      onMouseMove={handleMouseMove} 
      onMouseLeave={handleMouseLeave}
    >
      {showTooltip && (
        <div 
          style={{ 
            position: "absolute",
            left: tooltipPosition, 
            bottom: "30px",
            transform: "translateX(-50%)", 
            background: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            padding: "4px 8px",
            borderRadius: "4px",
            whiteSpace: "nowrap",
            pointerEvents: "none",
            fontSize: "12px"
          }}
        >
          {tooltipTime}
        </div>
      )}

      <input
        ref={inputRef}
        id="player-bar"
        type="range"
        min="0"
        max="100"
        value={sliderValue}
        onChange={handleChange}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        aria-label="Video Progress Bar"
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default ProgressBar;





