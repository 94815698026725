import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../redux/store"; // Adjust import based on your store setup

interface AuthProtectedRouteProps {
  children: JSX.Element;
}

const AuthProtectedRoute = ({ children }: AuthProtectedRouteProps) => {
  const {loggedIn, access_token} = useSelector((state: RootState) => state.user);
  const currentProgram = useSelector((state: RootState) => state.program.currentProgram);
  const location = useLocation();

  // If the user is authenticated, check their program status
  if (loggedIn && access_token) {
    // If no current program, redirect to the programs page
    if (!currentProgram) {
      return <Navigate to="/programs" state={{ from: location }} replace />;
    }
    // If a current program exists, redirect to home (or dashboard)
    return <Navigate to="/browse/home" state={{ from: location }} replace />;
  }

  // If the user is not authenticated, allow them to view the auth routes
  return children;
};

export default AuthProtectedRoute;
