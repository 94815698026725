import React from "react";
import { Button, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"; // For accessing authentication state
import { RootState } from "../../redux/store";

const Status404: React.FC = () => {
  // Access the authentication state from Redux
  const { loggedIn, access_token } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  // Conditionally determine the destination based on authentication
  const handleRedirect = () => {
    if (loggedIn && access_token) {
      navigate("/browse/home"); // Redirect to dashboard or another page if logged in
    } else {
      navigate("/auth/login"); // Redirect to login if not logged in
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column", // Stack image and text vertically
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      {/* Image Section */}
      <img
        src="/images/404.jpg" // Add the path to your image here
        alt="404 - Page Not Found"
        style={{
          maxWidth: "80%", // Ensures the image is responsive
          maxHeight: "400px", // Limit the height if the image is too big
          objectFit: "cover", // Crop the image if it exceeds the box
          marginBottom: "20px", // Space between image and text
        }}
      />

      {/* Text Section */}
      <Typography variant="h2" color="error">
        Nothing Here, Sorry
      </Typography>
      <Typography variant="h6" color="textSecondary" paragraph>
        Oops! The page you are looking for does not exist. It might have been
        moved or deleted.
      </Typography>

      {/* Button Section */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleRedirect} // Redirect user based on authentication status
        style={{
          marginTop: "10px",
          textTransform: "none",
          boxShadow: "none",
        }}
      >
        {loggedIn ? "Go to Dashboard" : "Log in to Continue"}
      </Button>
    </Container>
  );
};

export default Status404;
