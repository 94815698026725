import React from 'react';
import { Button, Typography, Box, Container, useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import { useNavigate } from 'react-router-dom';
import useBreakpoints from '../../hooks/useBreakPoints';

const PaymentUnsuccessfulPage: React.FC = () => {
  const theme = useTheme();
  const { isXs, isSm } = useBreakpoints();
  const navigate = useNavigate();

  const isMdUp = !isSm && !isXs;

  const handleNavigate = () => {
    navigate('/subscription/plans');
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '2rem' }}>
      <ErrorIcon sx={{ fontSize: 60, color: 'red', marginBottom: '1rem' }} />
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Payment Unsuccessful
        </Typography>
        <Typography variant="body1">
          Your payment did not go through. Please try again or contact support for more information.
        </Typography>
      </Box>

      <Button
        variant="contained"
        color="secondary"
        onClick={handleNavigate}
        sx={{
          width: "fit-content",
          fontSize: isMdUp ? "1rem" : "inherit",
          background: "#EC674C",
          opacity: 1,
          paddingX: theme.spacing(4),
          paddingY: theme.spacing(1),
          mt: theme.spacing(4),
          textTransform: "none",
          borderRadius: isMdUp ? "40px" : "30px",
          "&:hover": {
            background: "#EC674C",
          },
        }}
      >
        View Plans
      </Button>
    </Container>
  );
};

export default PaymentUnsuccessfulPage;
