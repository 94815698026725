import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { RootState } from "../redux/store";

interface ProtectedRouteProps {
  children: JSX.Element;
  category: "auth" | "program" | "main"; // Route category
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ category, children }) => {
  const { loggedIn, access_token } = useSelector((state: RootState) => state.user);
  const { currentProgram } = useSelector((state: RootState) => state.program);
  const location = useLocation();

  // Auth routes: no need for authentication
  if (category === "auth") {
    return children;
  }

  // Program routes: require login and token, but not currentProgram
  if (category === "program" && (!loggedIn || !access_token)) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  // Main app routes: require login, token, and currentProgram
  if (category === "main" && (!loggedIn || !access_token || !currentProgram)) {
    if (!loggedIn || !access_token) {
      return <Navigate to="/auth/login" state={{ from: location }} replace />;
    }
    if (!currentProgram) {
      return <Navigate to="/programs" state={{ from: location }} replace />;
    }
  }

  // If checks pass, render the children (protected route content)
  return children;
};

export default ProtectedRoute;
