import React from "react";
import { styled } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppRoutes } from "../constants/routes";
import * as Pages from "../pages";
import ProtectedRoute from "../utils/ProfileProtectedRoute"; // Make sure this is your updated ProtectedRoute
import DashboardLayout from "../layout/DashboardLayout";
import PaymentSuccessfulPage from "../pages/PaymentStatusPage/PaymentSuccessfulPage";
import PaymentUnsuccessfulPage from "../pages/PaymentStatusPage/PaymentUnsuccessfulPage";
import AuthProtectedRoute from "../utils/AuthProtectedRoute";

/**
 * @description Styled container for the base of the router component.
 */
const BaseContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
}));

/**
 * @description Router component managing all the application's routing.
 * Utilizes react-router-dom for navigation and route management.
 * @returns {React.ReactElement} - Rendered Routes within a base container.
 */
export const Router = (): React.ReactElement => {
  return (
    <BaseContainer>
      <Routes>
        {/** AUTH ROUTES - Routes for authentication-related pages */}
        <Route
          path={`/${AppRoutes.AuthBase}/*`}
          element={
            <AuthProtectedRoute>
              <Routes>
                <Route
                  path={AppRoutes.Auth.Login}
                  element={<Pages.LoginPage />}
                />
                <Route
                  path={AppRoutes.Auth.Signup}
                  element={<Pages.SignUpPage />}
                />
                <Route
                  path={AppRoutes.Auth.ForgotPassword}
                  element={<Pages.ForgotPasswordPage />}
                />
                <Route
                  path={AppRoutes.Auth.AccountVerification}
                  element={<Pages.VerifyAccountPage />}
                />
                <Route
                  path={AppRoutes.Auth.ChangePassword}
                  element={<Pages.ChangePasswordPage />}
                />
                <Route path="*" element={<Pages.Status404 />} />
              </Routes>
            </AuthProtectedRoute>
          }
        />

        {/** PROGRAM ROUTES - Routes for program-related pages within a protected route */}
        <Route
          path={`/${AppRoutes.ProgramBase}/*`}
          element={
            <ProtectedRoute category="program">
              <Routes>
                <Route
                  index
                  element={<Pages.SelectProgramPage mode="select" />}
                />
                <Route
                  path={`${AppRoutes.Programs.Manage}`}
                  element={<Pages.SelectProgramPage mode="manage" />}
                />
                <Route
                  path={`${AppRoutes.Programs.Create}`}
                  element={<Pages.AddProgramPage />}
                />
                <Route
                  path={`${AppRoutes.Programs.Update}`}
                  element={<Pages.EditProgramPage />}
                />
                <Route path="*" element={<Pages.Status404 />} />
              </Routes>
            </ProtectedRoute>
          }
        />

        {/** SUBSCRIPTION ROUTES - Routes for subscription-related pages */}
        <Route
          path={`/${AppRoutes.SubscriptionBase}/*`}
          element={
            <ProtectedRoute category="program">
              <Routes>
                <Route
                  path={`${AppRoutes.Subscriptions.Plans}`}
                  element={<Pages.SubscriptionPage />}
                />
                <Route path="*" element={<Pages.Status404 />} />
              </Routes>
            </ProtectedRoute>
          }
        />

        {/** PAYMENT STATUS ROUTES */}
        <Route
          path="/payment-success"
          element={
            <ProtectedRoute category="program">
              <PaymentSuccessfulPage />
            </ProtectedRoute>
          }
        />

        <Route
          path="/payment-cancelled"
          element={
            <ProtectedRoute category="program">
              <PaymentUnsuccessfulPage />
            </ProtectedRoute>
          }
        />

        {/** DASHBOARD ROUTES - Routes for dashboard-related pages */}
        <Route
          path={`/${AppRoutes.RouteBase}/*`}
          element={
            <ProtectedRoute category="main">
              <DashboardLayout>
                <Routes>
                  <Route
                    path={`/${AppRoutes.Browse.Home}`}
                    element={<Pages.LandingPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Search}`}
                    element={<Pages.SearchPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Settings}`}
                    element={<Pages.SettingsPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Playlists}`}
                    element={<Pages.PlaylistPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Stream}`}
                    element={<Pages.NowPlayingPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Categories}`}
                    element={<Pages.CategoriesPage />}
                  />
                  <Route
                    path={`/${AppRoutes.Browse.Notifications}`}
                    element={<Pages.NotificationsPage />}
                  />
                  <Route
                    path="/more-info/:videoId"
                    element={<Pages.MoreInfoPage />}
                  />

                  <Route
                    path="/"
                    element={<Navigate to={`${AppRoutes.Browse.Home}`} />}
                  />
                  <Route path="*" element={<Pages.Status404 />} />
                </Routes>
              </DashboardLayout>
            </ProtectedRoute>
          }
        />

        {/** HOME ROUTE - Redirects to appropriate page based on user status */}
        <Route
          path="/"
          element={
            <ProtectedRoute category="main">
              {/* Redirects user to /browse/home, will be handled within the ProtectedRoute */}
              <Navigate
                to={`/${AppRoutes.RouteBase}/${AppRoutes.Browse.Home}`}
              />
            </ProtectedRoute>
          }
        />

        <Route
          path="/terms-and-conditions"
          element={<Pages.TermsAndConditionsPage />}
        />

        {/** OTHER ROUTES - Catch-all or 404 Not Found route */}
        <Route path="*" element={<Pages.Status404 />} />
      </Routes>
    </BaseContainer>
  );
};
