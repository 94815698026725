import React from 'react';
import { Button, Typography, Box, Container, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import useBreakpoints from '../../hooks/useBreakPoints';

const PaymentSuccessfulPage: React.FC = () => {
  const theme = useTheme();
  const { isXs, isSm } = useBreakpoints();
  const navigate = useNavigate();

  const isMdUp = !isSm && !isXs;

  const handleNavigate = () => {
    navigate('/programs');
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '2rem' }}>
      <CheckCircleIcon sx={{ fontSize: 60, color: 'green', marginBottom: '1rem' }} />
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography variant="h4" gutterBottom>
          Payment Successful!
        </Typography>
        <Typography variant="body1">
          Thank you for your payment. You can now proceed to select your program.
        </Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleNavigate}
        sx={{
          width: "fit-content",
          fontSize: isMdUp ? "1rem" : "inherit",
          background: "#EC674C",
          opacity: 1,
          paddingX: theme.spacing(4),
          paddingY: theme.spacing(1),
          mt: theme.spacing(4),
          textTransform: "none",
          borderRadius: isMdUp ? "40px" : "30px",
          "&:hover": {
            background: "#EC674C",
          },
        }}
      >
        Go to Program Selection
      </Button>
    </Container>
  );
};

export default PaymentSuccessfulPage;
